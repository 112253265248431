import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Paper,
  Avatar,
  TextField,
  Button,
  useMediaQuery,
  Typography,
  Stack,
} from '@mui/material';
import { FaNotesMedical } from 'react-icons/fa';
import packageJson from '../../../package.json';
import {
  axiosPost,
  errorHandler,
  isValidTurkishMobilePhoneNumber,
} from '../../utils/utils';
import { authURL } from '../../config';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import PhoneInput from 'react-phone-input-2';
import tr from 'react-phone-input-2/lang/tr.json';
import 'react-phone-input-2/lib/material.css';
import { t2 } from '../../utils/Language';

const useStyles = createUseStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    width: '25vw',
    minWidth: 280,
    maxWidth: 400,
    maxHeight: '75vh',
    minHeight: 300,
    zIndex: 10,
  },
  paperHorizontal: {
    minHeight: '20vh',
    width: '60vw',
    backgroundColor: 'white',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    zIndex: 11,
  },
  phoneInput: {
    marginLeft: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
    borderTop: 'none !important',
    borderLeft: 'none !important',
    borderRight: 'none !important',
    borderRadius: '0 !important',
    width: '100% !important',
  },
  phoneInputError: {
    color: '#d32f2f',
    borderBottom: '1px solid #d32f2f !important',
  },
  avatar: {
    margin: 4,
    backgroundColor: 'rgb(227, 51, 113)',
    width: '10vh',
    height: '10vh',
    maxWidth: 120,
    maxHeight: 120,
    minWidth: 60,
    minHeight: 60,
  },
  icon: {
    marginLeft: -1,
    marginTop: -1,
    width: '5vh',
    height: '5vh',
    maxWidth: 60,
    maxHeight: 60,
    minWidth: 30,
    minHeight: 30,
  },
  form: {
    width: '100%',
    flex: 1,
    marginTop: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    color: 'gray',
    fontSize: 14,
    cursor: 'pointer',
    width: '100%',
    textAlign: 'right',
    marginTop: 10,
  },
  select: {
    width: '100%',
  },
  selectHorizontal: {
    width: '19vw',
  },
  buttonHorizontal: {
    color: 'conrflowerblue',
  },
  button: { width: '100%', color: 'conrflowerblue' },

  '@media screen and (max-width: 768px)': {
    paper: {
      minHeight: 'unset',
      marginTop: 10,
      marginBottom: 10,
      width: '80vw',
    },
    paperHorizontal: {
      flexDirection: 'column',
      paddingTop: 20,
      paddingBottom: 20,
      width: '90vw',
    },
    selectHorizontal: {
      width: '70vw',
      marginBottom: 20,
    },
  },
});

function LoginPanel() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const lowHeight = useMediaQuery('(max-height:640px)');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [code, setCode] = useState('');
  const [otpOpen, setOtpOpen] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (location.state?.message)
      enqueueSnackbar(location.state.message, { variant: 'success' });
  }, []);

  const handleLogin = () => {
    let error = false;
    if (phoneNumber.length === 0) {
      setPhoneError(true);
      error = true;
    }

    if (error) {
      enqueueSnackbar(t2.fillRequiredFields, {
        variant: 'warning',
      });
      return;
    }

    if (!isValidTurkishMobilePhoneNumber(phoneNumber)) {
      enqueueSnackbar(t2.enterValidPhoneNumber, {
        variant: 'warning',
      });
      setPhoneError(true);
      return;
    }

    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'macos-web';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'ios-web';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'windows-web';
    } else if (/Android/.test(userAgent)) {
      os = 'android-web';
    } else if (!os && /Linux/.test(platform)) {
      os = 'linux-web';
    }

    axiosPost(
      `${authURL}/publiclogin`,
      {
        phoneNumber,
        code,
        os,
        version: packageJson.version,
      },
      false,
    )
      .then(({ data }) => {
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('phoneNumber', phoneNumber);
        localStorage.setItem('name', data.name);
        localStorage.setItem('surname', data.surname);
        localStorage.setItem('gender', data.gender);
        localStorage.setItem('birthdate', data.birthdate);
        localStorage.setItem('email', data.email);
        localStorage.setItem('address', data.address);

        if (location?.state?.from) {
          // navigate to where I originated from containing the original state
          navigate(location.state.from.pathname, {
            state: { ...location.state.from.state },
          });
        } else {
          navigate('/inquiry/list');
        }
      })
      .catch((err) => {
        if (err.response.status === 428) {
          setCounter(err.response.data.remainingSeconds);
          setOtpOpen(true);
        } else if (err.response.status === 412) {
          setCounter(err.response.data.remainingSeconds);
          setOtpOpen(true);
          enqueueSnackbar(errorHandler(err, null), { variant: 'error' });
        } else {
          enqueueSnackbar(errorHandler(err, null), { variant: 'error' });
        }
      });
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <Avatar className={classes.avatar}>
        <FaNotesMedical className={classes.icon} />
      </Avatar>
      <PhoneInput
        country={'tr'}
        localization={tr}
        enableSearch={true}
        searchPlaceholder={t2.search}
        searchNotFound={t2.countryNotFound}
        specialLabel={t2.phoneNumber}
        countryCodeEditable={false}
        inputProps={{
          name: 'phoneNumber',
          required: true,
          autoFocus: true,
          form: {
            autoComplete: 'off',
          },
          onKeyDown: (e) => {
            if (e.key === 'Enter') {
              handleLogin();
            }
          },
        }}
        value={phoneNumber}
        onChange={setPhoneNumber}
        inputClass={`${classes.phoneInput} ${
          phoneError ? classes.phoneInputError : ''
        }`}
        style={{
          color: phoneError ? '#d32f2f' : '',
          marginTop: 20,
        }}
      />

      {otpOpen && (
        <Stack
          sx={{ mt: 3 }}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <TextField
            sx={{ m: 0, p: 0, width: '55%' }}
            variant='outlined'
            margin='normal'
            id='code'
            error={phoneError}
            label={t2.otpCode}
            name='code'
            autoFocus
            inputProps={{
              form: {
                autoComplete: 'off',
              },
              onKeyUp: (e) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              },
            }}
            onChange={(e) => setCode(e.target.value)}
            value={code}
          />
          <Typography sx={{ ml: 5 }}>Kalan süre </Typography>
          <CountdownCircleTimer
            isPlaying={true}
            onComplete={() => {
              enqueueSnackbar(t2.timeoutExceeded, {
                variant: 'error',
              });
              setCode('');
              setOtpOpen(false);
            }}
            size={50}
            strokeWidth={3}
            duration={180}
            initialRemainingTime={counter}
            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
            colorsTime={[180, 45, 30, 0]}>
            {({ remainingTime }) => (
              <Typography variant={'subtitle2'}>
                {remainingTime % 60 < 10
                  ? `0${Math.floor(remainingTime / 60)}:0${remainingTime % 60} `
                  : `0${Math.floor(remainingTime / 60)}:${remainingTime % 60} `}
              </Typography>
            )}
          </CountdownCircleTimer>
        </Stack>
      )}
      <Button
        fullWidth
        size={lowHeight ? 'small' : 'medium'}
        variant='contained'
        color='primary'
        style={{ marginTop: 40 }}
        onClick={handleLogin}>
        {t2.login}
      </Button>
    </Paper>
  );
}

export default LoginPanel;
