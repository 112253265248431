import { useState, useEffect, useRef, useMemo, memo } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { BsTrash } from 'react-icons/bs';
import MediaCard from './MediaCard';
import { useSnackbar } from 'notistack';
import { createUseStyles } from 'react-jss';
import promiseLimit from 'promise-limit';

import {
  axiosDelete,
  errorHandler,
  getLocalizedDateString,
} from '../../utils/utils';
import { publicPatientURL } from '../../config';
import MediaDetailModal from './MediaDetailModal';
import { t2 } from '../../utils/Language';

const useStyles = createUseStyles({
  thumbnailContainer: {
    width: 170,
    height: 150,
    margin: 10,
  },
  info: {
    marginBottom: 16,
    textAlign: 'center',
  },
});

function InquiryMediaView({ mediaList, onUpdateRequired }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteDialog, setDeleteDialog] = useState(false);
  const selectedMediaIdx = useRef(null);
  const [mediaDetailModal, setMediaDetailModal] = useState(false);

  const [selectedMedias, setSelectedMedias] = useState([]);
  const selectedMediasRef = useRef(selectedMedias);

  useEffect(() => {
    selectedMediasRef.current = selectedMedias;
  }, [selectedMedias]);

  const handleDelete = () => {
    let deletedMediaCount = 0;
    const limit = promiseLimit(5);

    const deleteMedia = async (id) => {
      await axiosDelete(`${publicPatientURL}/media/${id}`, true)
        .then(() => deletedMediaCount++)
        .catch((err) =>
          enqueueSnackbar(errorHandler(err, null), { variant: 'error' }),
        );
    };

    if (selectedMedias.length) {
      Promise.all(
        selectedMedias.map((media) => {
          return limit(() => deleteMedia(media.id));
        }),
      ).then(() => {
        setSelectedMedias([]);
        setDeleteDialog(false);
        onUpdateRequired();

        if (deletedMediaCount > 0) {
          enqueueSnackbar(`${deletedMediaCount} ${t2.nMediaDeleted}`, {
            variant: 'success',
          });
          if (deletedMediaCount !== selectedMedias.length) {
            enqueueSnackbar(
              `${selectedMedias.length - deletedMediaCount} ${
                t2.nMediaNotDeleted
              }`,
              {
                variant: 'error',
              },
            );
          }
        } else {
          enqueueSnackbar(t2.mediaNotDeleted, { variant: 'error' });
        }
      });
    }
  };

  function MediaList() {
    return (
      <>
        {mediaList?.map((media, idx) => (
          <MediaCard
            key={media.id}
            type={media.recordType}
            src={media.thumbnailUrl}
            title={getLocalizedDateString(media.date)}
            containerClass={classes.thumbnailContainer}
            onClick={() => {
              selectedMediaIdx.current = idx;
              setMediaDetailModal(true);
            }}
            onSelect={(isSelected) => {
              if (isSelected)
                setSelectedMedias([
                  ...selectedMediasRef.current,
                  {
                    id: media._id,
                    type: media.recordType,
                    imageUrl: media.imageUrl,
                  },
                ]);
              else {
                const filteredImgs = selectedMediasRef.current.filter(
                  (elem) => elem.id !== media._id,
                );
                setSelectedMedias(filteredImgs);
              }
            }}
          />
        ))}
      </>
    );
  }

  const MemoizedMediaList = useMemo(MediaList, [mediaList]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#314e52',
          borderRadius: 10,
          marginTop: 30,
          marginBottom: 5,
          border: '1px solid #314e52',
        }}>
        <Typography style={{ width: 'auto', fontSize: 17, color: 'white' }}>
          {t2.media}
        </Typography>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            background: 'white',
          }}>
          <Button
            variant='contained'
            disabled={selectedMedias.length < 1}
            startIcon={<BsTrash />}
            onClick={() => setDeleteDialog(true)}
            color='error'
            style={{ margin: 10 }}>
            {t2.delete}
          </Button>
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            background: 'white',
            borderBottomRightRadius: 9,
            borderBottomLeftRadius: 9,
          }}>
          {mediaList?.length === 0 && (
            <Typography className={classes.info}>{t2.noMedia}</Typography>
          )}
          {mediaList?.length > 0 && MemoizedMediaList}

          {mediaDetailModal && (
            <MediaDetailModal
              open={mediaDetailModal}
              handleClose={() => {
                setMediaDetailModal(false);
              }}
              url={mediaList[selectedMediaIdx.current].url}
              type={mediaList[selectedMediaIdx.current].recordType}
            />
          )}
        </div>
      </div>

      <Dialog
        open={deleteDialog}
        onClose={() => {
          setDeleteDialog(false);
        }}>
        <DialogTitle>{t2.areYouSure}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t2.approvalToDeleteMedia}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteDialog(false);
            }}
            color='primary'>
            {t2.cancel}
          </Button>
          <Button
            onClick={() => {
              handleDelete();
            }}
            color='error'>
            {t2.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(InquiryMediaView);
