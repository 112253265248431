import { BUILD_LANGUAGE } from '../config';

const localeStrings = {
  tr: {
    homepage: 'Anasayfa',
    howToUse: 'Nasıl Kullanırım',
    aboutUs: 'Hakkımızda',
    contact: 'İletişim',
    areYouDoctor: 'Doktor musunuz?',
    searchForADoctor: 'Doktor Ara',
    selectProficiency: 'Branş Seçiniz',
    selectCity: 'Şehir Seçiniz',
    noDoctorFound: 'İstediğiniz özelliklerde bir doktor bulunamadı',
    phone: 'Telefon',
    email: 'E-mail',
    address: 'Adres',
    specialties: 'Uzmanlık Alanları',
    resume: 'Özgeçmiş',
    about: 'Hakkında',
    interests: 'Çalışma Alanları',
    education: 'Eğitim Bilgileri',
    academic: 'Akademik Çalışmalar',
    showBigMap: 'Haritayı görüntüle',
    kvkk: 'Gizlilik ve Kişisel Verilerin Korunması Politikası',
    inquiryKvkk:
      '6698 sayılı KVKK kanunu kapsamında özel nitelikli kişisel verilerimin, tarafıma sağlanacak tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi ve planlanması amacıyla, sır saklama yükümlülüğü altında bulunan yukarıda ismi geçen kişi tarafından işlenmesine onay veriyorum.',
    inquiryIys: '6563 sayılı İYS kanunu kapsamında iletişim izni veriyorum.',
    inquiryRequest: 'Destek Talebi',
    newInquiryRequest: 'Yeni Destek Talebi',
    send: 'Gönder',
    cancel: 'İptal',
    delete: 'Sil',
    update: 'Güncelle',
    loginToInquire: 'Lütfen telefon numaranızla giriş yapınız',
    reloginToInquire: 'Lütfen tekrar giriş yapınız',

    sliderHeader_1: 'Aradığınız Doktoru Bulun',
    sliderText_1:
      'İhtiyacınız olan branş ve tercih ettiğiniz şehirleri girerek her biri konularında uzman olan doktorlarımızı listeleyin.',
    sliderHeader_2: 'Uzman Olduğu Konuları İnceleyin',
    sliderText_2:
      'Listelenen doktorların uzmanlaşma konularını, eğitimlerini, tecrübelerini inceleyin, ihtiyaçlarınıza tam olarak uyan doktoru bulun.',
    sliderHeader_3: 'Değerlendirme Talebi Gönderin',
    sliderText_3:
      'Kişisel bilgilerinizi, şikayetlerinizi, isteklerinizi yazın, fotoğraflarınızı yükleyerek doktorunuz tarafından değerlendirilmek üzere bir talep gönderin (çok yakında).',
    sliderHeader_4: 'Doktorunuzla İrtibata Geçin',
    sliderText_4:
      'Doktorunuzun lokasyon ve iletişim bilgilerini görün, ister direkt olarak iletişime geçin, isterseniz Whatsapp üzerinden mesaj gönderin.',

    htu_item_1:
      'Kullanıcı yaratmanıza gerek yoktur. Kişisel bilgileriniz hiçbir şekilde kaydedilmez.',
    htu_item_2: 'Şehir ve uzmanlık alanı belirterek arama yapın.',
    htu_item_3: 'Listelenen doktorların profil bilgilerini inceleyin.',
    htu_item_4:
      'İhtiyaçlarınıza tam olarak cevap verebilecek doktoru tespit edin.',
    htu_item_5:
      'İletişim bilgilerini kullanarak direkt olarak doktorunuzla iletişime geçebilirsiniz.',
    htu_item_6:
      "Doktorunuza e-posta ya da Whatsapp'tan direkt mesaj gönderebilirsiniz.",
    htu_item_7:
      'Arzu ederseniz kişisel bilgilerinizi, şikayetlerinizi, isteklerinizi ve fotoğraflarınızı ekleyip doktorunuza talep gönderebilirsiniz (çok yakında).',
    htu_item_8:
      'Doktorunuz en kısa sürede durumunuzu değerlendirip size dönüş yapacaktır.',

    aboutUsDescription:
      "Doğalzeka, 2006 yılında kurulmuş, Internet üzerinden video akıtma ve uygulamaları üzerinde uzmanlaşmış, %100 yerli sermayeli bir teknoloji ve yazılım geliştirme şirketi olup AR-GE faaliyetlerine Yıldız Teknik Üniversitesi Teknopark'ta devam etmektedir.",
    inquiries: 'Taleplerim',
    logout: 'Çıkış',
    inquiryRequestUpdated: 'Danışma talebiniz güncellendi',
    inquiryRequestCreated: 'Danışma talebiniz oluşturuldu',
    fillRequiredFields: 'Lütfen zorunlu alanları doldurunuz!',
    phoneNumber: 'Telefon Numarası',
    enterValidPhoneNumber: 'Lütfen geçerli bir telefon numarası giriniz!',
    countryNotFound: 'Ülke bulunamadı',
    search: 'Ara',
    otpCode: 'SMS Kodu',
    timeoutExceeded: 'Verilen sürede giriş yapılmadı!',
    login: 'Giriş Yap',
    noInquiryYet: 'Henüz bir destek talebinde bulunmadınız.',
    date: 'Tarih',
    doctor: 'Doktor',
    yourComplaint: 'Şikayetiniz',
    aprrovalToDelete: 'Bu talebi silmek istediğinize emin misiniz?',
    areYouSure: 'Emin misiniz?',
    yourName: 'Adınız',
    yourSurname: 'Soyadınız',
    birthdate: 'Doğum Tarihi',
    gender: 'Cinsiyet',
    male: 'Erkek',
    female: 'Kadın',
    notSpecified: 'Belirtilmemiş',
    yourResponse: 'Cevabınız',
    readAndApproved: 'nı okudum, onaylıyorum.',
    fieldsRequired: '* Bu alanlar zorunludur.',
    nMediaDeleted: 'medya silindi',
    nMediaNotDeleted: 'medya silinemedi',
    mediaNotDeleted: 'Medya(lar) silinemedi',
    media: 'Medyalar',
    noMedia: 'Herhangi bir medya bulunmamaktadır',
    approvalToDeleteMedia:
      'Seçili medya(ları) silmek istediğinize emin misiniz?',
    nMediaUploaded: 'medya başarıyla yüklendi',
    nMediaNotUploaded: 'medya yüklenemedi',
    mediaNotUploaded: 'Medya(lar) yüklenemedi',
    zoomIn: 'Yakınlaştır',
    zoomOut: 'Uzaklaştır',
    resetZoom: 'Sıfırla',
    fullScreen: 'Tam ekram',
    remove: 'Çıkar',
    openInNewTab: 'Yeni sekmede aç',
    prevPage: 'Önceki sayfa',
    nextPage: 'Sonraki sayfa',
    myInquiries: 'Destek Taleplerim',
  },
  en: {
    homepage: 'Home',
    howToUse: 'How To Use',
    aboutUs: 'About Us',
    contact: 'Contact',
    areYouDoctor: 'Are you a doctor?',
    searchForADoctor: 'Search for a Doctor',
    selectProficiency: 'Select Proficiency',
    selectCity: 'Select City',
    noDoctorFound: 'Found no doctors matching your criteria',
    phone: 'Phone',
    email: 'E-mail',
    address: 'Address',
    specialties: 'Specialties',
    resume: 'Resume',
    about: 'About',
    interests: 'Areas of Interest',
    education: 'Education Information',
    academic: 'Academic Studies',
    showBigMap: 'View Map',
    kvkk: 'Privacy and Personal Data Protection Policy',
    inquiryKvkk: '',
    inquiryIys: '',
    inquiryRequest: 'Inquiry',
    newInquiryRequest: 'New Inquiry Request',
    send: 'Send',
    cancel: 'Cancel',
    delete: 'Delete',
    update: 'Update',
    loginToInquire:
      'Please login with your phone number to send inquiry request.',
    reloginToInquire: 'Please re-login with your phone number.',

    sliderHeader_1: 'Search Our Team of Top Doctors',
    sliderText_1:
      'List our top notch doctors and surgeons by using the expertise area you seek and the city you prefer.',
    sliderHeader_2: "Explore Your Doctor's Expertise",
    sliderText_2:
      "Examine the listed doctors' specializations, training, experience and find the doctor that fits your needs exactly.",
    sliderHeader_3: 'Send an Enquiry To Your Doctor',
    sliderText_3:
      'Write your personal information, complaints, requests, upload your photos and send an enquiry to be evaluated by your doctor (coming soon).',
    sliderHeader_4: 'Contact Your Doctor Directly',
    sliderText_4:
      "See your doctor's location and contact information, contact him/her directly or send a message via Whatsapp.",

    htu_item_1:
      'You do not need to create a user. Your personal information is not recorded in any way.',
    htu_item_2:
      'Search among our database by city and the area of ​​expertise.',
    htu_item_3: 'View the profile information of the doctors.',
    htu_item_4: 'Identify the doctor who exactly fits your needs.',
    htu_item_5:
      'You can contact your doctor directly using the contact information provided.',
    htu_item_6: 'You can send an e-mail or a Whatsapp message to your doctor.',
    htu_item_7:
      'If you wish, you can add your personal information, complaints, requests, photos and send an enquiry to your doctor (coming soon).',
    htu_item_8:
      'Your doctor will evaluate your condition and get back to you as soon as possible.',

    aboutUsDescription:
      'Founded in 2006, Doğalzeka is a technology and software development company specializing in video streaming and applications over the Internet, with 100% domestic capital. The company continues its R&D activities at Yıldız Technical University Technopark.',

    inquiries: 'My Inquiries',
    logout: 'Logout',
    inquiryRequestUpdated: 'Your inquiry request has been updated',
    inquiryRequestCreated: 'Your inquiry request has been created',
    fillRequiredFields: 'Please fill in the required fields!',
    phoneNumber: 'Phone Number',
    enterValidPhoneNumber: 'Please enter a valid phone number!',
    countryNotFound: 'Country not found',
    search: 'Search',
    otpCode: 'OTP Code',
    timeoutExceeded: 'Login timed out!',
    login: 'Login',
    noInquiryYet: "You haven't made any support requests yet.",
    date: 'Date',
    doctor: 'Doctor',
    yourComplaint: 'Your Complaint',
    approvalToDelete: 'Are you sure you want to delete this request?',
    areYouSure: 'Are you sure?',
    yourName: 'Your Name',
    yourSurname: 'Your Surname',
    birthdate: 'Birthdate',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    notSpecified: 'Not Specified',
    yourResponse: 'Your Response',
    readAndApproved: ' I have read and approved.',
    fieldsRequired: '* These fields are required.',
    nMediaDeleted: 'media deleted',
    nMediaNotDeleted: 'media could not be deleted',
    mediaNotDeleted: 'Media could not be deleted',
    media: 'Media',
    noMedia: 'No media available',
    approvalToDeleteMedia:
      'Are you sure you want to delete the selected media?',
    nMediaUploaded: 'media uploaded successfully',
    nMediaNotUploaded: 'media could not be uploaded',
    mediaNotUploaded: 'Media could not be uploaded',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    resetZoom: 'Reset Zoom',
    fullScreen: 'Full Screen',
    remove: 'Remove',
    openInNewTab: 'Open in a new tab',
    prevPage: 'Previous Page',
    nextPage: 'Next Page',
    myInquiries: 'My Inquiries',
  },
};

const translate = (key) => {
  let value = localeStrings[BUILD_LANGUAGE][key];

  if (value === undefined) value = key;

  return value;
};

const translate2 = localeStrings[BUILD_LANGUAGE];

export { translate as t, translate2 as t2 };
