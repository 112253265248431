import { Card, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getLocalizedDateTimeString } from '../../utils/utils';

const useStyles = createUseStyles({
  container: {
    marginTop: 20,
    backgroundColor: 'whitesmoke',
    borderRadius: 10,
    padding: 24,
    width: '100%',
  },
  ownerMessageLine: {
    width: '100%',
    textAlign: 'right',
  },
  ownerMessageCard: {
    padding: 20,
    paddingLeft: 60,
    paddingBottom: 10,
    margin: 5,
    marginLeft: 'auto',
    maxWidth: '90%',
    display: 'inline-block',
    backgroundColor: 'lightblue',
  },
  otherMessageLine: { width: '100%', textAlign: 'left', alignItems: 'left' },
  otherMessageCard: {
    padding: 20,
    paddingRight: 60,
    paddingBottom: 10,
    margin: 5,
    maxWidth: '90%',
    display: 'inline-block',
    backgroundColor: 'white',
  },
});

function InquiryMessageView({ messageList }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {messageList.map((m) => {
        return (
          <div
            key={m.id}
            className={
              m.isOwner ? classes.ownerMessageLine : classes.otherMessageLine
            }>
            <Card
              className={
                m.isOwner ? classes.ownerMessageCard : classes.otherMessageCard
              }>
              <Typography>{m.text}</Typography>
              <Typography variant='caption'>
                {getLocalizedDateTimeString(m.timestamp)}
              </Typography>
            </Card>
          </div>
        );
      })}
    </div>
  );
}

export default InquiryMessageView;
