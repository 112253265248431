import { forwardRef, useImperativeHandle, useState, useRef } from 'react';
import { Typography, Stack, LinearProgress } from '@mui/material';
import MediaDropSelectPane from './MediaDropSelectPane';
import { publicPatientURL } from '../../config';
import {
  axiosUpload,
  errorHandler,
  RECORD_PATIENT_DOCUMENT,
} from '../../utils/utils';
import promiseLimit from 'promise-limit';
import { useSnackbar } from 'notistack';
import { t2 } from '../../utils/Language';

const MediaUploadPane = forwardRef(function ({ doctorId, onReady }, ref) {
  const { enqueueSnackbar } = useSnackbar();

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploading, setUploading] = useState(false);
  const mediaSelectRef = useRef(null);
  const totalMediaUploadSize = useRef(0);

  useImperativeHandle(
    ref,
    () => {
      return {
        async upload(inquiryId) {
          await postMedias(inquiryId);
        },
      };
    },
    [],
  );

  const postMedias = async (inquiryId) => {
    const limit = promiseLimit(5);
    let candidates = [];
    let sentBytesByFile = [];
    let uploadedMediaCount = 0;

    if (mediaSelectRef.current) {
      candidates = mediaSelectRef.current.getFileList();
    }

    const mediaCountToUpload = candidates.length;
    if (mediaCountToUpload === 0) {
      return; // nothing to upload
    }

    totalMediaUploadSize.current = candidates.reduce(
      (sum, cand) => sum + cand.file.size,
      0,
    );

    onReady(false);
    setUploading(true);

    const uploadJob = (data, index) => {
      return axiosUpload(`${publicPatientURL}/media`, data, {
        onUploadProgress: (ProgressEvent) => {
          sentBytesByFile[index] = ProgressEvent.loaded;

          const totalSent = sentBytesByFile.reduce(
            (sum, curr) => sum + curr,
            0,
          );

          let progress = Math.round(
            (totalSent * 100) / totalMediaUploadSize.current,
          );

          if (progress > 100) progress = 100;
          setUploadPercentage(progress);
        },
      })
        .then((response) => {
          uploadedMediaCount++;
          return response.status;
        })
        .catch((err) =>
          enqueueSnackbar(errorHandler(err, null), { variant: 'error' }),
        );
    };

    const generateFormData = async (file, thumbnail, recordType) => {
      const data = new FormData();

      data.append('file', file);
      data.append('recordType', recordType);
      data.append('size', file.size);
      data.append('inquiryId', inquiryId);
      data.append('doctorId', doctorId);

      if (recordType === RECORD_PATIENT_DOCUMENT && thumbnail) {
        data.append('thumbnail', thumbnail);
      }

      return data;
    };

    await Promise.all(
      candidates.map(async (cand, idx) => {
        const data = await generateFormData(
          cand.file,
          cand.thumbnail.file,
          cand.recordType,
        );

        return limit(() => uploadJob(data, idx));
      }),
    )
      .then(() => {
        onReady(true);
        setUploading(false);
        setUploadPercentage(0);

        mediaSelectRef.current.clearUploadedFiles(candidates);

        if (uploadedMediaCount > 0) {
          enqueueSnackbar(`${uploadedMediaCount} ${t2.nMediaUploaded}`, {
            variant: 'success',
          });

          if (uploadedMediaCount !== mediaCountToUpload) {
            enqueueSnackbar(
              `${mediaCountToUpload - uploadedMediaCount} ${
                t2.nMediNotaUploaded
              }`,
              {
                variant: 'error',
              },
            );
          }
        } else {
          enqueueSnackbar(t2.mediaNotUploaded, { variant: 'error' });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div>
      <div style={{ width: '100%' }}>
        <MediaDropSelectPane
          ref={mediaSelectRef}
          onReady={(ready) => onReady(ready)}
          disabled={uploading}
          style={{ height: 200, maxWidth: '100%' }}
        />
      </div>
      {uploading && (
        <Stack
          sx={{ width: '100%' }}
          flexDirection='row'
          justifyContent='center'
          alignItems='center'>
          <LinearProgress
            style={{
              width: 200,
              height: 10,
              borderRadius: 5,
              marginRight: 5,
            }}
            variant='determinate'
            value={uploadPercentage}
          />
          <Typography variant='body2'>{`${Math.round(
            uploadPercentage,
          )}%`}</Typography>
        </Stack>
      )}
    </div>
  );
});

export default MediaUploadPane;
