import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Typography, Checkbox, Tooltip, CircularProgress } from '@mui/material';
import { TiDeleteOutline } from 'react-icons/ti';
import { MdPictureAsPdf } from 'react-icons/md';
import { FiDownload } from 'react-icons/fi';
import { PiVideoFill } from 'react-icons/pi';
import ReactPlayer from 'react-player';

import {
  RECORD_PATIENT_PICTURE,
  RECORD_PATIENT_VIDEO,
  RECORD_PATIENT_DOCUMENT,
} from '../../utils/utils';

const useStyles = createUseStyles({
  mainContainer: {
    position: 'relative',
    userSelect: 'none',
    cursor: 'pointer',
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  title: {
    height: 30,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    background: '#487e95',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: 'white',
    position: 'relative',
  },
  deleteButtonContainer: {
    position: 'absolute',
    right: 0,
    borderRadius: '0px 6px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteIcon: {
    color: 'white',
    cursor: 'pointer',
    transition: 'transform .2s',
    '&:hover': {
      color: '#cc1b1b',
      transform: 'scale(1.1)',
    },
  },
  mediaContainer: {
    height: 'calc(100% - 30px)',
    width: '100%',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    cursor: 'pointer',
    background: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  image: {
    maxHeight: '90%',
    maxWidth: '100%',
  },
  video: {
    maxHeight: '98%',
    maxWidth: '100%',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    position: 'absolute',
    right: 5,
    bottom: 5,
    borderRadius: 25,
    background: 'rgba(255, 255, 255, 0.75)',
  },
  downloadButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 5,
    bottom: 5,
    borderRadius: 25,
    background: 'rgba(255, 255, 255, 0.75)',
  },
  downloadIcon: {
    fontSize: 25,
    color: '#487e95',
    background: 'white',
    borderRadius: 5,
    transition: 'transform .2s',
    '&:hover': {
      color: '#1976D2',
      transform: 'scale(1.2)',
    },
    zIndex: 999,
  },
  recordTypeIcon: {
    position: 'absolute',
    top: 5,
    left: 5,
    backgroundColor: 'whitesmoke',
    borderRadius: 3,
    opacity: 0.7,
  },
});

export default function MediaCard({
  type,
  src,
  title,
  selected,
  containerClass,
  containerStyle,
  onClick,
  onDelete,
  onSelect,
  onDownload,
  recordIconType,
}) {
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  return (
    <div
      className={`${classes.mainContainer} ${containerClass}`}
      style={containerStyle}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <div className={classes.title}>
        <Tooltip title={title}>
          <Typography
            noWrap
            style={{
              width: 'calc(100% - 56px)',
            }}>
            {title}
          </Typography>
        </Tooltip>
        {onDelete && (
          <Tooltip title='Sil'>
            <div
              className={classes.deleteButtonContainer}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}>
              <TiDeleteOutline className={classes.deleteIcon} size='1.75em' />
            </div>
          </Tooltip>
        )}
      </div>
      <div className={classes.mediaContainer} onClick={onClick}>
        {!src && <CircularProgress size={36} color='primary' />}
        {src && type === RECORD_PATIENT_PICTURE && (
          <img className={classes.image} src={src} alt={'Patient media'} />
        )}
        {src && type === RECORD_PATIENT_VIDEO && (
          <>
            <ReactPlayer
              className={classes.video}
              url={src}
              muted={true}
              playing={true}
              loop={true}
            />
            <PiVideoFill
              className={classes.recordTypeIcon}
              size={25}
              color={'#F25C52'}
            />
          </>
        )}
        {src && type === RECORD_PATIENT_DOCUMENT && (
          <>
            <img className={classes.image} src={src} alt={'PDF Document'} />
            <MdPictureAsPdf
              className={classes.recordTypeIcon}
              size={25}
              color={'#F25C52'}
            />
          </>
        )}

        {recordIconType === RECORD_PATIENT_VIDEO && (
          <PiVideoFill
            className={classes.recordTypeIcon}
            size={25}
            color={'#F25C52'}
          />
        )}
        {recordIconType === RECORD_PATIENT_DOCUMENT && (
          <MdPictureAsPdf
            className={classes.recordTypeIcon}
            size={25}
            color={'#F25C52'}
          />
        )}
      </div>
      {onDownload && hover && (
        <Tooltip title='İndir'>
          <div className={classes.downloadButtonContainer}>
            <FiDownload
              className={classes.downloadIcon}
              onClick={(e) => {
                e.stopPropagation();
                onDownload();
              }}
            />
          </div>
        </Tooltip>
      )}
      {onSelect && (
        <div className={classes.checkboxContainer}>
          <Checkbox
            color='primary'
            size={'small'}
            defaultChecked={selected}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              if (onSelect) onSelect(e.target.checked);
            }}
          />
        </div>
      )}
    </div>
  );
}
