import { Typography, TableCell, TableRow, Tooltip } from '@mui/material';

export const StyledTableCell = (props) => {
  return <TableCell>{props.children}</TableCell>;
};

export const StyledTableRow = (props) => {
  return (
    <TableRow onClick={props.onClick} style={{ cursor: 'pointer' }}>
      {props.children}
    </TableRow>
  );
};

export function ToolTippedCell({ text, className }) {
  return (
    <Tooltip
      title={text}
      enterDelay={500}
      enterNextDelay={500}
      placement='bottom-start'>
      <Typography noWrap className={className}>
        {text}
      </Typography>
    </Tooltip>
  );
}
