import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { createUseStyles } from 'react-jss';
import { BsTrash } from 'react-icons/bs';
import { MdOutlinePermMedia, MdNotificationsNone } from 'react-icons/md';
import { getLocalizedDateString } from '../../utils/utils';
import { t2 } from '../../utils/Language';
import { ToolTippedCell, StyledTableCell, StyledTableRow } from './StyledTable';

const useStyles = createUseStyles({
  table: {
    minWidth: 650,
    tableLayout: 'fixed',
  },
  date: {
    width: 100,
    paddingLeft: 20,
  },
  doctorTitle: {
    width: '30%',
  },
  title: {
    width: '70%',
  },
  badgeContainer: {
    width: 120,
  },
  badge: {
    marginRight: 5,
  },
  deleteButton: {
    marginLeft: 15,
    color: 'red',
    transition: 'all 0.1s linear',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  text: {
    width: '100%',
    fontSize: 14,
  },
});

function InquiryListTable({ inquiryData, handleClick, handleDelete }) {
  const classes = useStyles();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedInquiryIdx, setSelectedInquiryIdx] = useState(null);

  const openDeleteDialog = (idx, event) => {
    event.stopPropagation();
    setSelectedInquiryIdx(idx);
    setDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
    setSelectedInquiryIdx(null);
  };

  const confirmDelete = () => {
    if (selectedInquiryIdx !== null) {
      handleDelete(selectedInquiryIdx);
    }
    closeDeleteDialog();
  };

  return (
    <>
      <TableContainer>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.date}>
                {t2.date}
              </StyledTableCell>
              <StyledTableCell className={classes.doctorTitle}>
                {t2.doctor}
              </StyledTableCell>
              <StyledTableCell className={classes.title}>
                {t2.yourComplaint}
              </StyledTableCell>
              <StyledTableCell
                className={classes.badgeContainer}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inquiryData &&
              inquiryData.map((row, idx) => (
                <StyledTableRow
                  key={row._id}
                  onClick={() => handleClick(row._id, row.doctor.id)}>
                  <StyledTableCell
                    className={classes.date}
                    component='th'
                    scope='row'>
                    {getLocalizedDateString(row.timestamp)}
                  </StyledTableCell>
                  <StyledTableCell className={classes.doctorTitle}>
                    <ToolTippedCell
                      text={row.doctor.nameSurname}
                      className={classes.text}
                    />
                  </StyledTableCell>
                  <StyledTableCell className={classes.complaint}>
                    <ToolTippedCell
                      text={row.complaint}
                      className={classes.text}
                    />
                  </StyledTableCell>
                  <TableCell
                    className={classes.badgeContainer}
                    align='right'
                    style={{ padding: 0, paddingRight: 25 }}>
                    {row.mediaCount > 0 && (
                      <MdOutlinePermMedia
                        className={classes.badge}
                        size={'1.3em'}
                      />
                    )}
                    {row.new && (
                      <MdNotificationsNone
                        className={classes.badge}
                        color='red'
                        size={'1.3em'}
                      />
                    )}
                    <BsTrash
                      className={classes.deleteButton}
                      onClick={(e) => openDeleteDialog(idx, e)}
                      size={'1.3em'}
                    />
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={deleteDialog}
        onClose={closeDeleteDialog}
        style={{ background: 'rgba(0,0,0,0.2)' }}>
        <DialogTitle id='alert-dialog-title'>{t2.areYouSure}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t2.aprrovalToDelete}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color='primary'>
            {t2.cancel}
          </Button>
          <Button onClick={confirmDelete} color='error'>
            {t2.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InquiryListTable;
